import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  signUp,
  confirmSignUp,
  resetPassword,
  confirmResetPassword,
  resendSignUpCode,
  signIn,
  signInWithRedirect,
  signOut,
  fetchUserAttributes,
  fetchAuthSession,
} from "aws-amplify/auth";

import { Cache } from "aws-amplify/utils";

type CognitoUserData = {
  id: string;
  email: string;
  name: string;
  picture: string;
  stripeID: string;
};

export class AWSCognitoManager {
  protected static man: AWSCognitoManager;
  public static get Instance() {
    if (!this.man) {
      this.man = new AWSCognitoManager();
    }
    return this.man;
  }

  async isUserLoggedIn(): Promise<boolean | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const session = await fetchAuthSession();
        resolve(session.tokens != undefined);
      } catch (error) {
        reject(error);
      }
    });
  }

  async fetchCurrentUserID(): Promise<string | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        resolve((await this.getCurrentCognitoUser())?.id);
      } catch (error) {
        reject(error);
      }
    });
  }
  async fetchCurrentIdentityID(): Promise<string | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const session = await fetchAuthSession();
        resolve(session.identityId);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getCurrentCognitoUser(): Promise<CognitoUserData | undefined> {
    // console.log("getting user");
    // console.trace();
    return new Promise(async (resolve, reject) => {
      try {
        // const user = await Auth.currentAuthenticatedUser({
        //   bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        // });
        // const { username, userId, signInDetails } = await getCurrentUser();
        const attributes = await fetchUserAttributes();
        // const user = await this.auth().currentAuthenticatedUser();

        // const credentials = await this.auth().currentUserCredentials();
        // console.log("identityId", credentials.identityId);

        const cognitoUser = {
          id: attributes.sub,
          email: attributes.email,
          name: attributes.name,
          picture: attributes.picture,
          stripeID: attributes["custom:stripeCustomer_ID"],
        } as CognitoUserData;

        resolve(cognitoUser);
      } catch (error) {
        reject(error);
      }
    });
  }

  async signUp(email: string, password: string, name: string) {
    var attributes: { [key: string]: any } = {
      email: email,
    };
    if (name != null) attributes["name"] = name;
    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: attributes,
        },
      });
    } catch (error) {
      console.log("signup failed with error:");
      console.log(error);
      throw error;
    }
  }

  async confirmSignUp(email: string, verificationCode: string) {
    await confirmSignUp({
      username: email,
      confirmationCode: verificationCode,
    });
  }
  async forgotPassword(email: string) {
    await resetPassword({ username: email });
  }
  async forgotPasswordReset(email: string, code: string, password: string) {
    await confirmResetPassword({
      username: email,
      confirmationCode: code,
      newPassword: password,
    });
  }
  async resendConfirmation(email: string) {
    await resendSignUpCode({ username: email });
  }

  async signInWithGoogle(customState?: string) {
    return signInWithRedirect({ provider: "Google", customState });
  }

  async logIn(username: string, password: string) {
    return signIn({ username, password });
  }

  async signUserOut() {
    await AsyncStorage.clear();
    await this.clearCache();
    await signOut();
  }
  async clearCache() {
    let keysToDelete = await Cache.getAllKeys();
    for (var key of keysToDelete) {
      await Cache.removeItem(key);
    }
  }
}

export default AWSCognitoManager.Instance;
