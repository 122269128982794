/* tslint:disable */
/* eslint-disable */
import * as APITypes from "../Codegen/API_WithTypename";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listSocialManPostsMinifiedDataByCreatedAt =
  /* GraphQL */ `query listSocialManPostsMinifiedDataByCreatedAt(
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelSocialManPostFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
  $userID: ID!
) {
  listSocialManPostsByCreatedAt(
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userID: $userID
  ) {
    items {
      createdAt
      description
      id
      s3Key
      s3KeyResized
      status
      title
      updatedAt
      userID
      facebook {
        thumbnail
      }
      youtube {
        thumbnailURL
      }
      instagram {
        thumbnail
      }
      pinterest {
        thumbnailURL
      }
      postedTime
      postedToFacebook
      postedToInstagram
      postedToPinterest
      postedToTiktok
      postedToTwitter
      postedToYoutube
    }
    nextToken
  }
}
` as GeneratedQuery<
    APITypes.ListSocialManPostsByCreatedAtQueryVariables,
    APITypes.ListSocialManPostsByCreatedAtQuery
  >;

export const getUserFromUsername = /* GraphQL */ `
  query getUserFromUsername($username: String!) {
    getUserFromUsername(username: $username) {
      id
      name
      username
      currentSubscriptionPlan
      periodEndOfLastSubscriptionCredits
      createdPresetCount
      completedTasksCount
      stripeCustomerID
      stripeConnectAccountID
      stripeConnectAccountSetupComplete
      email
      picture
      credits
      redeemableCredits
      createdAt
      updatedAt
    }
  }
`;

export const getUserBasic = /* GraphQL */ `query GetUserBasic($id: ID!) {
  getUser(id: $id) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    createdAt
    updatedAt
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;

export const batchGetUsersBasic =
  /* GraphQL */ `query BatchGetUsersBasic($ids: [ID]!) {
  batchGetUsers(ids: $ids) {
    id
    name
    username
    createdPresetCount
    completedTasksCount
    picture
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
    APITypes.BatchGetUsersQueryVariables,
    APITypes.BatchGetUsersQuery
  >;

export const getModelsTaskID = /* GraphQL */ `
  query GetModelsTaskID($id: ID!) {
    getModels(id: $id) {
      id
      mltaskdataID
    }
  }
`;

export const getModelInputPresetByIDFull = /* GraphQL */ `
  query GetModelInputPresetFull($id: ID!) {
    getModelInputPreset(id: $id) {
      id
      presetName
      description
      isPublic
      isOpenSource
      modelsID
      userID
      username
      taskName
      modelName
      cost
      createdAt
      updatedAt
      usage
      versionHistory {
        cost
        versionName
        entries {
          modelInputName
          modelInputType
          presetEntryDatas {
            entryType
            parameterName
            description
            val
            presetOrder
            metaData {
              FILE_NAME
              FILE_TYPE
              FILE_SIZE_KB
              DURATION
              WIDTH
              HEIGHT
              STRING_LENGTH
              TOKENS_COUNT
            }
          }
        }
      }
      versionName
      entries {
        modelInputName
        modelInputType
        presetEntryDatas {
          entryType
          parameterName
          description
          val
          presetOrder
          metaData {
            FILE_NAME
            FILE_TYPE
            FILE_SIZE_KB
            DURATION
            WIDTH
            HEIGHT
            STRING_LENGTH
            TOKENS_COUNT
          }
        }
      }
    }
  }
`;

export const getModelInputPresetByIDMini = /* GraphQL */ `
  query GetModelInputPresetMini($id: ID!) {
    getModelInputPreset(id: $id) {
      id
      presetName
      description
      isPublic
      isOpenSource
      modelsID
      userID
      username
      taskName
      modelName
      cost
      createdAt
      updatedAt
      usage
      versionName
    }
  }
`;

export const modelInputPresetsByUserID = /* GraphQL */ `
  query ModelInputPresetsByUserIDCustom(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelModelInputPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    modelInputPresetsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        presetName
        description
        isPublic
        isOpenSource
        modelsID
        userID
        username
        taskName
        modelName
        cost
        createdAt
        updatedAt
        usage
        versionHistory {
          cost
          versionName
          entries {
            modelInputName
            modelInputType
            presetEntryDatas {
              entryType
              parameterName
              description
              val
              presetOrder
              metaData {
                FILE_NAME
                FILE_TYPE
                FILE_SIZE_KB
                DURATION
                WIDTH
                HEIGHT
                STRING_LENGTH
                TOKENS_COUNT
              }
            }
          }
        }
        versionName
        entries {
          modelInputName
          modelInputType
          presetEntryDatas {
            entryType
            parameterName
            description
            val
            presetOrder
            metaData {
              FILE_NAME
              FILE_TYPE
              FILE_SIZE_KB
              WIDTH
              HEIGHT
              STRING_LENGTH
              TOKENS_COUNT
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const modelInputPresetsByModelsID = /* GraphQL */ `
  query ModelInputPresetsByModelsIDCustom(
    $modelsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelModelInputPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    modelInputPresetsByModelsID(
      modelsID: $modelsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        presetName
        description
        isPublic
        isOpenSource
        modelsID
        userID
        username
        taskName
        modelName
        cost
        createdAt
        updatedAt
        usage
        versionHistory {
          cost
          versionName
          entries {
            modelInputName
            modelInputType
            presetEntryDatas {
              entryType
              parameterName
              description
              val
              presetOrder
              metaData {
                FILE_NAME
                FILE_TYPE
                FILE_SIZE_KB
                DURATION
                WIDTH
                HEIGHT
                STRING_LENGTH
                TOKENS_COUNT
              }
            }
          }
        }
        versionName
        entries {
          modelInputName
          modelInputType
          presetEntryDatas {
            entryType
            parameterName
            description
            val
            presetOrder
            metaData {
              FILE_NAME
              FILE_TYPE
              FILE_SIZE_KB
              WIDTH
              HEIGHT
              STRING_LENGTH
              TOKENS_COUNT
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const onFixedUpdateMLTaskExecutionResult = /* GraphQL */ `
  subscription OnUpdateMLTaskExecutionResult2(
    $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
    $userID: String
  ) {
    onUpdateMLTaskExecutionResult(filter: $filter, userID: $userID) {
      id
      taskName
      modelName
      privacyLevel
      inputs {
        name
        value
        type
      }
      presetUserInput {
        presetName
        presetCreatorName
        presetCreatorUID
        presetValues {
          modelInputName
          presetEntryDatas {
            val
            presetOrder
            parameterName
          }
        }
      }
      output {
        message
        entries {
          name
          value
          type
        }
      }
      creditsUsed
      status
    }
  }
`;

export const onCreateMLTaskExecutionResult = /* GraphQL */ `
  subscription OnCreateMLTaskExecutionResult2(
    $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
    $userID: String
  ) {
    onCreateMLTaskExecutionResult(filter: $filter, userID: $userID) {
      id
      userID
      taskName
      modelName
      privacyLevel
      inputs {
        name
        value
        type
      }
      output {
        message
        entries {
          name
          value
          type
        }
      }
      status
    }
  }
`;

export const getAllMLTaskDataById = /* GraphQL */ `
  query getMLTaskDataQuery($id: ID!) {
    getMLTaskData(id: $id) {
      id
      name
      category
      isPublic
      Models(filter: { isPublic: { eq: true } }) {
        items {
          id
          name
          mltaskdataID
          outputs {
            entries {
              name
              type
            }
          }
          inputs {
            entries {
              defaultValue
              description
              examples
              name
              optionType
              options {
                name
                value
                description
              }
              optionStep
              type
              constraints {
                type
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const listAllMLTaskData = /* GraphQL */ `
  query ListTasks(
    $filter: ModelMLTaskDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMLTaskData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        category
        isPublic
        Models(filter: { isPublic: { eq: true } }) {
          items {
            id
            name
            mltaskdataID
            outputs {
              entries {
                name
                type
              }
            }
            inputs {
              entries {
                defaultValue
                description
                examples
                name
                optionType
                isRequired
                cost {
                  tokenizerModel
                  costPerToken
                  costPerCharacter
                  costPerValue
                  costPerWord
                  costInterpolateMin
                  costInterpolateMax
                  costPerFileSize
                  costPerFileDuration
                  flatFee
                  isMultiplier
                }
                options {
                  name
                  value
                  description
                  cost
                }
                optionStep
                type
                constraints {
                  type
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getAllUserExecutedTasks = /* GraphQL */ `
  query getUserExecutedTasks(
    $nextToken: String
    $sortDirection: ModelSortDirection
    $limit: Int
    $userID: ID!
  ) {
    listMLTaskExecutionResultsByCreatedAt(
      userID: $userID
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        id
        mltasksworkspaceID
        userID
        createdAt
        status
        creditsUsed
        modelName
        privacyLevel
        modelsID
        taskName
        inputs {
          name
          value
          type
        }
        output {
          message
          entries {
            name
            type
            value
          }
        }
        presetUserInput {
          presetID
          presetName
          presetVersion
          presetCreatorName
          presetCreatorUID
          presetValues {
            modelInputName
            presetEntryDatas {
              parameterName
              val
              presetOrder
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listPublicMLTaskExecutionResults = /* GraphQL */ `
  query CustomListMLTaskExecutionResults(
    $filter: ModelMLTaskExecutionResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMLTaskExecutionResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        createdAt
        status
        creditsUsed
        modelName
        privacyLevel
        modelsID
        taskName
        inputs {
          name
          value
          type
        }
        output {
          message
          entries {
            name
            type
            value
          }
        }
        presetUserInput {
          presetID
          presetName
          presetCreatorName
          presetCreatorUID
          presetValues {
            modelInputName
            presetEntryDatas {
              parameterName
              val
              presetOrder
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getUserCredits = /* GraphQL */ `
  query GetUserCredits($id: ID!) {
    getUser(id: $id) {
      id
      credits
    }
  }
`;
