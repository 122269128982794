/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API_WithTypename";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const updateMLTaskExecutionResultNoDS = /* GraphQL */ `mutation UpdateMLTaskExecutionResultNoDS(
  $input: UpdateMLTaskExecutionResultInputNoDS!
) {
  updateMLTaskExecutionResultNoDS(input: $input) {
    id
    userID
    HEARTReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    WOWReactionStats
    SADReactionStats
    ANGRYReactionStats
    SCARYReactionStats
    POOPReactionStats
    GROSSReactionStats
    Reactions {
      items {
        userID
        type
        mltaskexecutionresultID
        createdAt
        updatedAt
      }
      nextToken
    }
    taskName
    modelName
    privacyLevel
    inputs {
      name
      value
      type
      metaData {
        FILE_NAME
        FILE_TYPE
        FILE_SIZE_KB
        DURATION
        WIDTH
        HEIGHT
        STRING_LENGTH
        TOKENS_COUNT
      }
    }
    output {
      message
      entries {
        name
        value
        type
      }
    }
    creditsUsed
    status
    presetUserInput {
      presetID
      presetVersion
      presetName
      presetCreatorName
      presetCreatorUID
      presetValues {
        modelInputName
      }
    }
    createdAt
    mltasksworkspaceID
    modelsID
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMLTaskExecutionResultNoDSMutationVariables,
  APITypes.UpdateMLTaskExecutionResultNoDSMutation
>;
export const updateUserNoDS = /* GraphQL */ `mutation UpdateUserNoDS($input: UpdateUserInputNoDS!) {
  updateUserNoDS(input: $input) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserNoDSMutationVariables,
  APITypes.UpdateUserNoDSMutation
>;
export const updateSocialManPostNoDS = /* GraphQL */ `mutation UpdateSocialManPostNoDS($input: UpdateSocialManPostInputNoDS!) {
  updateSocialManPostNoDS(input: $input) {
    id
    title
    description
    s3Key
    s3KeyResized
    userID
    createdAt
    postedTime
    scheduledPostTime
    status
    tiktok {
      title
      caption
      privacy
      usersCanComment
      usersCanDuet
      usersCanStitch
      contentDisclosureEnabled
      contentDisclosureYourBrand
      contentDisclosureBrandedContent
      videoCoverTimestampMs
      postID
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      title
      description
      link
      thumbnailURL
      boardID
      pinID
    }
    youtube {
      title
      description
      tags
      thumbnailURL
      privacy
      category
      targetChannelID
      videoID
    }
    instagram {
      caption
      thumbnail
      targetAccountID
      postToStory
      postID
      storyID
    }
    facebook {
      caption
      thumbnail
      targetPageID
      postToStory
      postID
      storyID
      storyURL
    }
    twitter {
      caption
      tweetID
    }
    postedToTiktok
    postedToLinkedin
    postedToPinterest
    postedToYoutube
    postedToFacebook
    postedToInstagram
    postedToTwitter
    enabledTiktok
    enabledLinkedin
    enabledPinterest
    enabledYoutube
    enabledFacebook
    enabledInstagram
    enabledTwitter
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSocialManPostNoDSMutationVariables,
  APITypes.UpdateSocialManPostNoDSMutation
>;
export const updateUserUsername = /* GraphQL */ `mutation UpdateUserUsername($userID: ID!, $username: String!) {
  updateUserUsername(userID: $userID, username: $username) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserUsernameMutationVariables,
  APITypes.UpdateUserUsernameMutation
>;
export const createMLTasksWorkspace = /* GraphQL */ `mutation CreateMLTasksWorkspace(
  $input: CreateMLTasksWorkspaceInput!
  $condition: ModelMLTasksWorkspaceConditionInput
) {
  createMLTasksWorkspace(input: $input, condition: $condition) {
    id
    name
    description
    userID
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMLTasksWorkspaceMutationVariables,
  APITypes.CreateMLTasksWorkspaceMutation
>;
export const updateMLTasksWorkspace = /* GraphQL */ `mutation UpdateMLTasksWorkspace(
  $input: UpdateMLTasksWorkspaceInput!
  $condition: ModelMLTasksWorkspaceConditionInput
) {
  updateMLTasksWorkspace(input: $input, condition: $condition) {
    id
    name
    description
    userID
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMLTasksWorkspaceMutationVariables,
  APITypes.UpdateMLTasksWorkspaceMutation
>;
export const deleteMLTasksWorkspace = /* GraphQL */ `mutation DeleteMLTasksWorkspace(
  $input: DeleteMLTasksWorkspaceInput!
  $condition: ModelMLTasksWorkspaceConditionInput
) {
  deleteMLTasksWorkspace(input: $input, condition: $condition) {
    id
    name
    description
    userID
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMLTasksWorkspaceMutationVariables,
  APITypes.DeleteMLTasksWorkspaceMutation
>;
export const createModelInputPreset = /* GraphQL */ `mutation CreateModelInputPreset(
  $input: CreateModelInputPresetInput!
  $condition: ModelModelInputPresetConditionInput
) {
  createModelInputPreset(input: $input, condition: $condition) {
    id
    presetName
    description
    cost
    usage
    isPublic
    isOpenSource
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        entryType
        parameterName
        val
        description
        presetOrder
      }
    }
    versionHistory {
      versionName
      cost
      entries {
        modelInputName
        modelInputType
      }
    }
    versionName
    username
    taskName
    modelName
    modelsID
    userID
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateModelInputPresetMutationVariables,
  APITypes.CreateModelInputPresetMutation
>;
export const updateModelInputPreset = /* GraphQL */ `mutation UpdateModelInputPreset(
  $input: UpdateModelInputPresetInput!
  $condition: ModelModelInputPresetConditionInput
) {
  updateModelInputPreset(input: $input, condition: $condition) {
    id
    presetName
    description
    cost
    usage
    isPublic
    isOpenSource
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        entryType
        parameterName
        val
        description
        presetOrder
      }
    }
    versionHistory {
      versionName
      cost
      entries {
        modelInputName
        modelInputType
      }
    }
    versionName
    username
    taskName
    modelName
    modelsID
    userID
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateModelInputPresetMutationVariables,
  APITypes.UpdateModelInputPresetMutation
>;
export const deleteModelInputPreset = /* GraphQL */ `mutation DeleteModelInputPreset(
  $input: DeleteModelInputPresetInput!
  $condition: ModelModelInputPresetConditionInput
) {
  deleteModelInputPreset(input: $input, condition: $condition) {
    id
    presetName
    description
    cost
    usage
    isPublic
    isOpenSource
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        entryType
        parameterName
        val
        description
        presetOrder
      }
    }
    versionHistory {
      versionName
      cost
      entries {
        modelInputName
        modelInputType
      }
    }
    versionName
    username
    taskName
    modelName
    modelsID
    userID
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteModelInputPresetMutationVariables,
  APITypes.DeleteModelInputPresetMutation
>;
export const createTaskReaction = /* GraphQL */ `mutation CreateTaskReaction(
  $input: CreateTaskReactionInput!
  $condition: ModelTaskReactionConditionInput
) {
  createTaskReaction(input: $input, condition: $condition) {
    userID
    type
    mltaskexecutionresultID
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskReactionMutationVariables,
  APITypes.CreateTaskReactionMutation
>;
export const updateTaskReaction = /* GraphQL */ `mutation UpdateTaskReaction(
  $input: UpdateTaskReactionInput!
  $condition: ModelTaskReactionConditionInput
) {
  updateTaskReaction(input: $input, condition: $condition) {
    userID
    type
    mltaskexecutionresultID
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskReactionMutationVariables,
  APITypes.UpdateTaskReactionMutation
>;
export const deleteTaskReaction = /* GraphQL */ `mutation DeleteTaskReaction(
  $input: DeleteTaskReactionInput!
  $condition: ModelTaskReactionConditionInput
) {
  deleteTaskReaction(input: $input, condition: $condition) {
    userID
    type
    mltaskexecutionresultID
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskReactionMutationVariables,
  APITypes.DeleteTaskReactionMutation
>;
export const createMLTaskExecutionResult = /* GraphQL */ `mutation CreateMLTaskExecutionResult(
  $input: CreateMLTaskExecutionResultInput!
  $condition: ModelMLTaskExecutionResultConditionInput
) {
  createMLTaskExecutionResult(input: $input, condition: $condition) {
    id
    userID
    HEARTReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    WOWReactionStats
    SADReactionStats
    ANGRYReactionStats
    SCARYReactionStats
    POOPReactionStats
    GROSSReactionStats
    Reactions {
      items {
        userID
        type
        mltaskexecutionresultID
        createdAt
        updatedAt
      }
      nextToken
    }
    taskName
    modelName
    privacyLevel
    inputs {
      name
      value
      type
      metaData {
        FILE_NAME
        FILE_TYPE
        FILE_SIZE_KB
        DURATION
        WIDTH
        HEIGHT
        STRING_LENGTH
        TOKENS_COUNT
      }
    }
    output {
      message
      entries {
        name
        value
        type
      }
    }
    creditsUsed
    status
    presetUserInput {
      presetID
      presetVersion
      presetName
      presetCreatorName
      presetCreatorUID
      presetValues {
        modelInputName
      }
    }
    createdAt
    mltasksworkspaceID
    modelsID
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMLTaskExecutionResultMutationVariables,
  APITypes.CreateMLTaskExecutionResultMutation
>;
export const updateMLTaskExecutionResult = /* GraphQL */ `mutation UpdateMLTaskExecutionResult(
  $input: UpdateMLTaskExecutionResultInput!
  $condition: ModelMLTaskExecutionResultConditionInput
) {
  updateMLTaskExecutionResult(input: $input, condition: $condition) {
    id
    userID
    HEARTReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    WOWReactionStats
    SADReactionStats
    ANGRYReactionStats
    SCARYReactionStats
    POOPReactionStats
    GROSSReactionStats
    Reactions {
      items {
        userID
        type
        mltaskexecutionresultID
        createdAt
        updatedAt
      }
      nextToken
    }
    taskName
    modelName
    privacyLevel
    inputs {
      name
      value
      type
      metaData {
        FILE_NAME
        FILE_TYPE
        FILE_SIZE_KB
        DURATION
        WIDTH
        HEIGHT
        STRING_LENGTH
        TOKENS_COUNT
      }
    }
    output {
      message
      entries {
        name
        value
        type
      }
    }
    creditsUsed
    status
    presetUserInput {
      presetID
      presetVersion
      presetName
      presetCreatorName
      presetCreatorUID
      presetValues {
        modelInputName
      }
    }
    createdAt
    mltasksworkspaceID
    modelsID
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMLTaskExecutionResultMutationVariables,
  APITypes.UpdateMLTaskExecutionResultMutation
>;
export const deleteMLTaskExecutionResult = /* GraphQL */ `mutation DeleteMLTaskExecutionResult(
  $input: DeleteMLTaskExecutionResultInput!
  $condition: ModelMLTaskExecutionResultConditionInput
) {
  deleteMLTaskExecutionResult(input: $input, condition: $condition) {
    id
    userID
    HEARTReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    WOWReactionStats
    SADReactionStats
    ANGRYReactionStats
    SCARYReactionStats
    POOPReactionStats
    GROSSReactionStats
    Reactions {
      items {
        userID
        type
        mltaskexecutionresultID
        createdAt
        updatedAt
      }
      nextToken
    }
    taskName
    modelName
    privacyLevel
    inputs {
      name
      value
      type
      metaData {
        FILE_NAME
        FILE_TYPE
        FILE_SIZE_KB
        DURATION
        WIDTH
        HEIGHT
        STRING_LENGTH
        TOKENS_COUNT
      }
    }
    output {
      message
      entries {
        name
        value
        type
      }
    }
    creditsUsed
    status
    presetUserInput {
      presetID
      presetVersion
      presetName
      presetCreatorName
      presetCreatorUID
      presetValues {
        modelInputName
      }
    }
    createdAt
    mltasksworkspaceID
    modelsID
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMLTaskExecutionResultMutationVariables,
  APITypes.DeleteMLTaskExecutionResultMutation
>;
export const createCreditTransactionsHistory = /* GraphQL */ `mutation CreateCreditTransactionsHistory(
  $input: CreateCreditTransactionsHistoryInput!
  $condition: ModelCreditTransactionsHistoryConditionInput
) {
  createCreditTransactionsHistory(input: $input, condition: $condition) {
    id
    eventID
    userID
    cashAmountInCents
    creditAmount
    status
    transactionType
    context {
      taskID
      presetID
      presetVersion
      subscriptionID
      priceID
      transactionID
      feedback
      comment
      reason
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateCreditTransactionsHistoryMutationVariables,
  APITypes.CreateCreditTransactionsHistoryMutation
>;
export const updateCreditTransactionsHistory = /* GraphQL */ `mutation UpdateCreditTransactionsHistory(
  $input: UpdateCreditTransactionsHistoryInput!
  $condition: ModelCreditTransactionsHistoryConditionInput
) {
  updateCreditTransactionsHistory(input: $input, condition: $condition) {
    id
    eventID
    userID
    cashAmountInCents
    creditAmount
    status
    transactionType
    context {
      taskID
      presetID
      presetVersion
      subscriptionID
      priceID
      transactionID
      feedback
      comment
      reason
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateCreditTransactionsHistoryMutationVariables,
  APITypes.UpdateCreditTransactionsHistoryMutation
>;
export const deleteCreditTransactionsHistory = /* GraphQL */ `mutation DeleteCreditTransactionsHistory(
  $input: DeleteCreditTransactionsHistoryInput!
  $condition: ModelCreditTransactionsHistoryConditionInput
) {
  deleteCreditTransactionsHistory(input: $input, condition: $condition) {
    id
    eventID
    userID
    cashAmountInCents
    creditAmount
    status
    transactionType
    context {
      taskID
      presetID
      presetVersion
      subscriptionID
      priceID
      transactionID
      feedback
      comment
      reason
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteCreditTransactionsHistoryMutationVariables,
  APITypes.DeleteCreditTransactionsHistoryMutation
>;
export const createModels = /* GraphQL */ `mutation CreateModels(
  $input: CreateModelsInput!
  $condition: ModelModelsConditionInput
) {
  createModels(input: $input, condition: $condition) {
    id
    isPublic
    name
    inputs {
      entries {
        type
        name
        defaultValue
        optionType
        optionStep
        description
        examples
        isRequired
      }
    }
    outputs {
      entries {
        type
        name
      }
    }
    mltaskdataID
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateModelsMutationVariables,
  APITypes.CreateModelsMutation
>;
export const updateModels = /* GraphQL */ `mutation UpdateModels(
  $input: UpdateModelsInput!
  $condition: ModelModelsConditionInput
) {
  updateModels(input: $input, condition: $condition) {
    id
    isPublic
    name
    inputs {
      entries {
        type
        name
        defaultValue
        optionType
        optionStep
        description
        examples
        isRequired
      }
    }
    outputs {
      entries {
        type
        name
      }
    }
    mltaskdataID
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateModelsMutationVariables,
  APITypes.UpdateModelsMutation
>;
export const deleteModels = /* GraphQL */ `mutation DeleteModels(
  $input: DeleteModelsInput!
  $condition: ModelModelsConditionInput
) {
  deleteModels(input: $input, condition: $condition) {
    id
    isPublic
    name
    inputs {
      entries {
        type
        name
        defaultValue
        optionType
        optionStep
        description
        examples
        isRequired
      }
    }
    outputs {
      entries {
        type
        name
      }
    }
    mltaskdataID
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteModelsMutationVariables,
  APITypes.DeleteModelsMutation
>;
export const createMLTaskData = /* GraphQL */ `mutation CreateMLTaskData(
  $input: CreateMLTaskDataInput!
  $condition: ModelMLTaskDataConditionInput
) {
  createMLTaskData(input: $input, condition: $condition) {
    id
    name
    category
    isPublic
    Models {
      items {
        id
        isPublic
        name
        mltaskdataID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMLTaskDataMutationVariables,
  APITypes.CreateMLTaskDataMutation
>;
export const updateMLTaskData = /* GraphQL */ `mutation UpdateMLTaskData(
  $input: UpdateMLTaskDataInput!
  $condition: ModelMLTaskDataConditionInput
) {
  updateMLTaskData(input: $input, condition: $condition) {
    id
    name
    category
    isPublic
    Models {
      items {
        id
        isPublic
        name
        mltaskdataID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMLTaskDataMutationVariables,
  APITypes.UpdateMLTaskDataMutation
>;
export const deleteMLTaskData = /* GraphQL */ `mutation DeleteMLTaskData(
  $input: DeleteMLTaskDataInput!
  $condition: ModelMLTaskDataConditionInput
) {
  deleteMLTaskData(input: $input, condition: $condition) {
    id
    name
    category
    isPublic
    Models {
      items {
        id
        isPublic
        name
        mltaskdataID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMLTaskDataMutationVariables,
  APITypes.DeleteMLTaskDataMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createSocialManPost = /* GraphQL */ `mutation CreateSocialManPost(
  $input: CreateSocialManPostInput!
  $condition: ModelSocialManPostConditionInput
) {
  createSocialManPost(input: $input, condition: $condition) {
    id
    title
    description
    s3Key
    s3KeyResized
    userID
    createdAt
    postedTime
    scheduledPostTime
    status
    tiktok {
      title
      caption
      privacy
      usersCanComment
      usersCanDuet
      usersCanStitch
      contentDisclosureEnabled
      contentDisclosureYourBrand
      contentDisclosureBrandedContent
      videoCoverTimestampMs
      postID
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      title
      description
      link
      thumbnailURL
      boardID
      pinID
    }
    youtube {
      title
      description
      tags
      thumbnailURL
      privacy
      category
      targetChannelID
      videoID
    }
    instagram {
      caption
      thumbnail
      targetAccountID
      postToStory
      postID
      storyID
    }
    facebook {
      caption
      thumbnail
      targetPageID
      postToStory
      postID
      storyID
      storyURL
    }
    twitter {
      caption
      tweetID
    }
    postedToTiktok
    postedToLinkedin
    postedToPinterest
    postedToYoutube
    postedToFacebook
    postedToInstagram
    postedToTwitter
    enabledTiktok
    enabledLinkedin
    enabledPinterest
    enabledYoutube
    enabledFacebook
    enabledInstagram
    enabledTwitter
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSocialManPostMutationVariables,
  APITypes.CreateSocialManPostMutation
>;
export const updateSocialManPost = /* GraphQL */ `mutation UpdateSocialManPost(
  $input: UpdateSocialManPostInput!
  $condition: ModelSocialManPostConditionInput
) {
  updateSocialManPost(input: $input, condition: $condition) {
    id
    title
    description
    s3Key
    s3KeyResized
    userID
    createdAt
    postedTime
    scheduledPostTime
    status
    tiktok {
      title
      caption
      privacy
      usersCanComment
      usersCanDuet
      usersCanStitch
      contentDisclosureEnabled
      contentDisclosureYourBrand
      contentDisclosureBrandedContent
      videoCoverTimestampMs
      postID
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      title
      description
      link
      thumbnailURL
      boardID
      pinID
    }
    youtube {
      title
      description
      tags
      thumbnailURL
      privacy
      category
      targetChannelID
      videoID
    }
    instagram {
      caption
      thumbnail
      targetAccountID
      postToStory
      postID
      storyID
    }
    facebook {
      caption
      thumbnail
      targetPageID
      postToStory
      postID
      storyID
      storyURL
    }
    twitter {
      caption
      tweetID
    }
    postedToTiktok
    postedToLinkedin
    postedToPinterest
    postedToYoutube
    postedToFacebook
    postedToInstagram
    postedToTwitter
    enabledTiktok
    enabledLinkedin
    enabledPinterest
    enabledYoutube
    enabledFacebook
    enabledInstagram
    enabledTwitter
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSocialManPostMutationVariables,
  APITypes.UpdateSocialManPostMutation
>;
export const deleteSocialManPost = /* GraphQL */ `mutation DeleteSocialManPost(
  $input: DeleteSocialManPostInput!
  $condition: ModelSocialManPostConditionInput
) {
  deleteSocialManPost(input: $input, condition: $condition) {
    id
    title
    description
    s3Key
    s3KeyResized
    userID
    createdAt
    postedTime
    scheduledPostTime
    status
    tiktok {
      title
      caption
      privacy
      usersCanComment
      usersCanDuet
      usersCanStitch
      contentDisclosureEnabled
      contentDisclosureYourBrand
      contentDisclosureBrandedContent
      videoCoverTimestampMs
      postID
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      title
      description
      link
      thumbnailURL
      boardID
      pinID
    }
    youtube {
      title
      description
      tags
      thumbnailURL
      privacy
      category
      targetChannelID
      videoID
    }
    instagram {
      caption
      thumbnail
      targetAccountID
      postToStory
      postID
      storyID
    }
    facebook {
      caption
      thumbnail
      targetPageID
      postToStory
      postID
      storyID
      storyURL
    }
    twitter {
      caption
      tweetID
    }
    postedToTiktok
    postedToLinkedin
    postedToPinterest
    postedToYoutube
    postedToFacebook
    postedToInstagram
    postedToTwitter
    enabledTiktok
    enabledLinkedin
    enabledPinterest
    enabledYoutube
    enabledFacebook
    enabledInstagram
    enabledTwitter
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSocialManPostMutationVariables,
  APITypes.DeleteSocialManPostMutation
>;
export const adjustUserCredit = /* GraphQL */ `mutation AdjustUserCredit($input: UpdateUserInput!, $incrementValue: Int!) {
  adjustUserCredit(input: $input, incrementValue: $incrementValue) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.AdjustUserCreditMutationVariables,
  APITypes.AdjustUserCreditMutation
>;
export const updateModelInputPresetIncrementUsage = /* GraphQL */ `mutation UpdateModelInputPresetIncrementUsage(
  $input: UpdateModelInputPresetInput!
  $incrementValue: Int
  $condition: ModelModelInputPresetConditionInput
) {
  updateModelInputPresetIncrementUsage(
    input: $input
    incrementValue: $incrementValue
    condition: $condition
  ) {
    id
    presetName
    description
    cost
    usage
    isPublic
    isOpenSource
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        entryType
        parameterName
        val
        description
        presetOrder
      }
    }
    versionHistory {
      versionName
      cost
      entries {
        modelInputName
        modelInputType
      }
    }
    versionName
    username
    taskName
    modelName
    modelsID
    userID
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateModelInputPresetIncrementUsageMutationVariables,
  APITypes.UpdateModelInputPresetIncrementUsageMutation
>;
