import { CustomError } from "./CustomErrors";

export enum StripeErrorCodes {
  WAIT_FOR_24_HOURS = "wait_24",
  UNKNOWN = "unknown",
}

export class StripeError extends CustomError<StripeErrorCodes> {
  constructor(code: StripeErrorCodes, message = "error occurred") {
    super(message, code);
    Object.setPrototypeOf(this, StripeError.prototype);
  }
}
