/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API_WithTypename";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateMLTasksWorkspace = /* GraphQL */ `subscription OnCreateMLTasksWorkspace(
  $filter: ModelSubscriptionMLTasksWorkspaceFilterInput
  $userID: String
) {
  onCreateMLTasksWorkspace(filter: $filter, userID: $userID) {
    id
    name
    description
    userID
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMLTasksWorkspaceSubscriptionVariables,
  APITypes.OnCreateMLTasksWorkspaceSubscription
>;
export const onUpdateMLTasksWorkspace = /* GraphQL */ `subscription OnUpdateMLTasksWorkspace(
  $filter: ModelSubscriptionMLTasksWorkspaceFilterInput
  $userID: String
) {
  onUpdateMLTasksWorkspace(filter: $filter, userID: $userID) {
    id
    name
    description
    userID
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMLTasksWorkspaceSubscriptionVariables,
  APITypes.OnUpdateMLTasksWorkspaceSubscription
>;
export const onDeleteMLTasksWorkspace = /* GraphQL */ `subscription OnDeleteMLTasksWorkspace(
  $filter: ModelSubscriptionMLTasksWorkspaceFilterInput
  $userID: String
) {
  onDeleteMLTasksWorkspace(filter: $filter, userID: $userID) {
    id
    name
    description
    userID
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMLTasksWorkspaceSubscriptionVariables,
  APITypes.OnDeleteMLTasksWorkspaceSubscription
>;
export const onCreateModelInputPreset = /* GraphQL */ `subscription OnCreateModelInputPreset(
  $filter: ModelSubscriptionModelInputPresetFilterInput
  $userID: String
) {
  onCreateModelInputPreset(filter: $filter, userID: $userID) {
    id
    presetName
    description
    cost
    usage
    isPublic
    isOpenSource
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        entryType
        parameterName
        val
        description
        presetOrder
      }
    }
    versionHistory {
      versionName
      cost
      entries {
        modelInputName
        modelInputType
      }
    }
    versionName
    username
    taskName
    modelName
    modelsID
    userID
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateModelInputPresetSubscriptionVariables,
  APITypes.OnCreateModelInputPresetSubscription
>;
export const onUpdateModelInputPreset = /* GraphQL */ `subscription OnUpdateModelInputPreset(
  $filter: ModelSubscriptionModelInputPresetFilterInput
  $userID: String
) {
  onUpdateModelInputPreset(filter: $filter, userID: $userID) {
    id
    presetName
    description
    cost
    usage
    isPublic
    isOpenSource
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        entryType
        parameterName
        val
        description
        presetOrder
      }
    }
    versionHistory {
      versionName
      cost
      entries {
        modelInputName
        modelInputType
      }
    }
    versionName
    username
    taskName
    modelName
    modelsID
    userID
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateModelInputPresetSubscriptionVariables,
  APITypes.OnUpdateModelInputPresetSubscription
>;
export const onDeleteModelInputPreset = /* GraphQL */ `subscription OnDeleteModelInputPreset(
  $filter: ModelSubscriptionModelInputPresetFilterInput
  $userID: String
) {
  onDeleteModelInputPreset(filter: $filter, userID: $userID) {
    id
    presetName
    description
    cost
    usage
    isPublic
    isOpenSource
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        entryType
        parameterName
        val
        description
        presetOrder
      }
    }
    versionHistory {
      versionName
      cost
      entries {
        modelInputName
        modelInputType
      }
    }
    versionName
    username
    taskName
    modelName
    modelsID
    userID
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteModelInputPresetSubscriptionVariables,
  APITypes.OnDeleteModelInputPresetSubscription
>;
export const onCreateTaskReaction = /* GraphQL */ `subscription OnCreateTaskReaction(
  $filter: ModelSubscriptionTaskReactionFilterInput
) {
  onCreateTaskReaction(filter: $filter) {
    userID
    type
    mltaskexecutionresultID
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTaskReactionSubscriptionVariables,
  APITypes.OnCreateTaskReactionSubscription
>;
export const onUpdateTaskReaction = /* GraphQL */ `subscription OnUpdateTaskReaction(
  $filter: ModelSubscriptionTaskReactionFilterInput
) {
  onUpdateTaskReaction(filter: $filter) {
    userID
    type
    mltaskexecutionresultID
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTaskReactionSubscriptionVariables,
  APITypes.OnUpdateTaskReactionSubscription
>;
export const onDeleteTaskReaction = /* GraphQL */ `subscription OnDeleteTaskReaction(
  $filter: ModelSubscriptionTaskReactionFilterInput
) {
  onDeleteTaskReaction(filter: $filter) {
    userID
    type
    mltaskexecutionresultID
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTaskReactionSubscriptionVariables,
  APITypes.OnDeleteTaskReactionSubscription
>;
export const onCreateMLTaskExecutionResult = /* GraphQL */ `subscription OnCreateMLTaskExecutionResult(
  $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
  $userID: String
) {
  onCreateMLTaskExecutionResult(filter: $filter, userID: $userID) {
    id
    userID
    HEARTReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    WOWReactionStats
    SADReactionStats
    ANGRYReactionStats
    SCARYReactionStats
    POOPReactionStats
    GROSSReactionStats
    Reactions {
      items {
        userID
        type
        mltaskexecutionresultID
        createdAt
        updatedAt
      }
      nextToken
    }
    taskName
    modelName
    privacyLevel
    inputs {
      name
      value
      type
      metaData {
        FILE_NAME
        FILE_TYPE
        FILE_SIZE_KB
        DURATION
        WIDTH
        HEIGHT
        STRING_LENGTH
        TOKENS_COUNT
      }
    }
    output {
      message
      entries {
        name
        value
        type
      }
    }
    creditsUsed
    status
    presetUserInput {
      presetID
      presetVersion
      presetName
      presetCreatorName
      presetCreatorUID
      presetValues {
        modelInputName
      }
    }
    createdAt
    mltasksworkspaceID
    modelsID
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMLTaskExecutionResultSubscriptionVariables,
  APITypes.OnCreateMLTaskExecutionResultSubscription
>;
export const onUpdateMLTaskExecutionResult = /* GraphQL */ `subscription OnUpdateMLTaskExecutionResult(
  $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
  $userID: String
) {
  onUpdateMLTaskExecutionResult(filter: $filter, userID: $userID) {
    id
    userID
    HEARTReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    WOWReactionStats
    SADReactionStats
    ANGRYReactionStats
    SCARYReactionStats
    POOPReactionStats
    GROSSReactionStats
    Reactions {
      items {
        userID
        type
        mltaskexecutionresultID
        createdAt
        updatedAt
      }
      nextToken
    }
    taskName
    modelName
    privacyLevel
    inputs {
      name
      value
      type
      metaData {
        FILE_NAME
        FILE_TYPE
        FILE_SIZE_KB
        DURATION
        WIDTH
        HEIGHT
        STRING_LENGTH
        TOKENS_COUNT
      }
    }
    output {
      message
      entries {
        name
        value
        type
      }
    }
    creditsUsed
    status
    presetUserInput {
      presetID
      presetVersion
      presetName
      presetCreatorName
      presetCreatorUID
      presetValues {
        modelInputName
      }
    }
    createdAt
    mltasksworkspaceID
    modelsID
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMLTaskExecutionResultSubscriptionVariables,
  APITypes.OnUpdateMLTaskExecutionResultSubscription
>;
export const onDeleteMLTaskExecutionResult = /* GraphQL */ `subscription OnDeleteMLTaskExecutionResult(
  $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
  $userID: String
) {
  onDeleteMLTaskExecutionResult(filter: $filter, userID: $userID) {
    id
    userID
    HEARTReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    WOWReactionStats
    SADReactionStats
    ANGRYReactionStats
    SCARYReactionStats
    POOPReactionStats
    GROSSReactionStats
    Reactions {
      items {
        userID
        type
        mltaskexecutionresultID
        createdAt
        updatedAt
      }
      nextToken
    }
    taskName
    modelName
    privacyLevel
    inputs {
      name
      value
      type
      metaData {
        FILE_NAME
        FILE_TYPE
        FILE_SIZE_KB
        DURATION
        WIDTH
        HEIGHT
        STRING_LENGTH
        TOKENS_COUNT
      }
    }
    output {
      message
      entries {
        name
        value
        type
      }
    }
    creditsUsed
    status
    presetUserInput {
      presetID
      presetVersion
      presetName
      presetCreatorName
      presetCreatorUID
      presetValues {
        modelInputName
      }
    }
    createdAt
    mltasksworkspaceID
    modelsID
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMLTaskExecutionResultSubscriptionVariables,
  APITypes.OnDeleteMLTaskExecutionResultSubscription
>;
export const onCreateCreditTransactionsHistory = /* GraphQL */ `subscription OnCreateCreditTransactionsHistory(
  $filter: ModelSubscriptionCreditTransactionsHistoryFilterInput
  $userID: String
) {
  onCreateCreditTransactionsHistory(filter: $filter, userID: $userID) {
    id
    eventID
    userID
    cashAmountInCents
    creditAmount
    status
    transactionType
    context {
      taskID
      presetID
      presetVersion
      subscriptionID
      priceID
      transactionID
      feedback
      comment
      reason
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCreditTransactionsHistorySubscriptionVariables,
  APITypes.OnCreateCreditTransactionsHistorySubscription
>;
export const onUpdateCreditTransactionsHistory = /* GraphQL */ `subscription OnUpdateCreditTransactionsHistory(
  $filter: ModelSubscriptionCreditTransactionsHistoryFilterInput
  $userID: String
) {
  onUpdateCreditTransactionsHistory(filter: $filter, userID: $userID) {
    id
    eventID
    userID
    cashAmountInCents
    creditAmount
    status
    transactionType
    context {
      taskID
      presetID
      presetVersion
      subscriptionID
      priceID
      transactionID
      feedback
      comment
      reason
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCreditTransactionsHistorySubscriptionVariables,
  APITypes.OnUpdateCreditTransactionsHistorySubscription
>;
export const onDeleteCreditTransactionsHistory = /* GraphQL */ `subscription OnDeleteCreditTransactionsHistory(
  $filter: ModelSubscriptionCreditTransactionsHistoryFilterInput
  $userID: String
) {
  onDeleteCreditTransactionsHistory(filter: $filter, userID: $userID) {
    id
    eventID
    userID
    cashAmountInCents
    creditAmount
    status
    transactionType
    context {
      taskID
      presetID
      presetVersion
      subscriptionID
      priceID
      transactionID
      feedback
      comment
      reason
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCreditTransactionsHistorySubscriptionVariables,
  APITypes.OnDeleteCreditTransactionsHistorySubscription
>;
export const onCreateModels = /* GraphQL */ `subscription OnCreateModels($filter: ModelSubscriptionModelsFilterInput) {
  onCreateModels(filter: $filter) {
    id
    isPublic
    name
    inputs {
      entries {
        type
        name
        defaultValue
        optionType
        optionStep
        description
        examples
        isRequired
      }
    }
    outputs {
      entries {
        type
        name
      }
    }
    mltaskdataID
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateModelsSubscriptionVariables,
  APITypes.OnCreateModelsSubscription
>;
export const onUpdateModels = /* GraphQL */ `subscription OnUpdateModels($filter: ModelSubscriptionModelsFilterInput) {
  onUpdateModels(filter: $filter) {
    id
    isPublic
    name
    inputs {
      entries {
        type
        name
        defaultValue
        optionType
        optionStep
        description
        examples
        isRequired
      }
    }
    outputs {
      entries {
        type
        name
      }
    }
    mltaskdataID
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateModelsSubscriptionVariables,
  APITypes.OnUpdateModelsSubscription
>;
export const onDeleteModels = /* GraphQL */ `subscription OnDeleteModels($filter: ModelSubscriptionModelsFilterInput) {
  onDeleteModels(filter: $filter) {
    id
    isPublic
    name
    inputs {
      entries {
        type
        name
        defaultValue
        optionType
        optionStep
        description
        examples
        isRequired
      }
    }
    outputs {
      entries {
        type
        name
      }
    }
    mltaskdataID
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteModelsSubscriptionVariables,
  APITypes.OnDeleteModelsSubscription
>;
export const onCreateMLTaskData = /* GraphQL */ `subscription OnCreateMLTaskData(
  $filter: ModelSubscriptionMLTaskDataFilterInput
) {
  onCreateMLTaskData(filter: $filter) {
    id
    name
    category
    isPublic
    Models {
      items {
        id
        isPublic
        name
        mltaskdataID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMLTaskDataSubscriptionVariables,
  APITypes.OnCreateMLTaskDataSubscription
>;
export const onUpdateMLTaskData = /* GraphQL */ `subscription OnUpdateMLTaskData(
  $filter: ModelSubscriptionMLTaskDataFilterInput
) {
  onUpdateMLTaskData(filter: $filter) {
    id
    name
    category
    isPublic
    Models {
      items {
        id
        isPublic
        name
        mltaskdataID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMLTaskDataSubscriptionVariables,
  APITypes.OnUpdateMLTaskDataSubscription
>;
export const onDeleteMLTaskData = /* GraphQL */ `subscription OnDeleteMLTaskData(
  $filter: ModelSubscriptionMLTaskDataFilterInput
) {
  onDeleteMLTaskData(filter: $filter) {
    id
    name
    category
    isPublic
    Models {
      items {
        id
        isPublic
        name
        mltaskdataID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMLTaskDataSubscriptionVariables,
  APITypes.OnDeleteMLTaskDataSubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $id: String
) {
  onCreateUser(filter: $filter, id: $id) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $id: String
) {
  onUpdateUser(filter: $filter, id: $id) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $id: String
) {
  onDeleteUser(filter: $filter, id: $id) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateSocialManPost = /* GraphQL */ `subscription OnCreateSocialManPost(
  $filter: ModelSubscriptionSocialManPostFilterInput
  $userID: String
) {
  onCreateSocialManPost(filter: $filter, userID: $userID) {
    id
    title
    description
    s3Key
    s3KeyResized
    userID
    createdAt
    postedTime
    scheduledPostTime
    status
    tiktok {
      title
      caption
      privacy
      usersCanComment
      usersCanDuet
      usersCanStitch
      contentDisclosureEnabled
      contentDisclosureYourBrand
      contentDisclosureBrandedContent
      videoCoverTimestampMs
      postID
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      title
      description
      link
      thumbnailURL
      boardID
      pinID
    }
    youtube {
      title
      description
      tags
      thumbnailURL
      privacy
      category
      targetChannelID
      videoID
    }
    instagram {
      caption
      thumbnail
      targetAccountID
      postToStory
      postID
      storyID
    }
    facebook {
      caption
      thumbnail
      targetPageID
      postToStory
      postID
      storyID
      storyURL
    }
    twitter {
      caption
      tweetID
    }
    postedToTiktok
    postedToLinkedin
    postedToPinterest
    postedToYoutube
    postedToFacebook
    postedToInstagram
    postedToTwitter
    enabledTiktok
    enabledLinkedin
    enabledPinterest
    enabledYoutube
    enabledFacebook
    enabledInstagram
    enabledTwitter
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSocialManPostSubscriptionVariables,
  APITypes.OnCreateSocialManPostSubscription
>;
export const onUpdateSocialManPost = /* GraphQL */ `subscription OnUpdateSocialManPost(
  $filter: ModelSubscriptionSocialManPostFilterInput
  $userID: String
) {
  onUpdateSocialManPost(filter: $filter, userID: $userID) {
    id
    title
    description
    s3Key
    s3KeyResized
    userID
    createdAt
    postedTime
    scheduledPostTime
    status
    tiktok {
      title
      caption
      privacy
      usersCanComment
      usersCanDuet
      usersCanStitch
      contentDisclosureEnabled
      contentDisclosureYourBrand
      contentDisclosureBrandedContent
      videoCoverTimestampMs
      postID
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      title
      description
      link
      thumbnailURL
      boardID
      pinID
    }
    youtube {
      title
      description
      tags
      thumbnailURL
      privacy
      category
      targetChannelID
      videoID
    }
    instagram {
      caption
      thumbnail
      targetAccountID
      postToStory
      postID
      storyID
    }
    facebook {
      caption
      thumbnail
      targetPageID
      postToStory
      postID
      storyID
      storyURL
    }
    twitter {
      caption
      tweetID
    }
    postedToTiktok
    postedToLinkedin
    postedToPinterest
    postedToYoutube
    postedToFacebook
    postedToInstagram
    postedToTwitter
    enabledTiktok
    enabledLinkedin
    enabledPinterest
    enabledYoutube
    enabledFacebook
    enabledInstagram
    enabledTwitter
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSocialManPostSubscriptionVariables,
  APITypes.OnUpdateSocialManPostSubscription
>;
export const onDeleteSocialManPost = /* GraphQL */ `subscription OnDeleteSocialManPost(
  $filter: ModelSubscriptionSocialManPostFilterInput
  $userID: String
) {
  onDeleteSocialManPost(filter: $filter, userID: $userID) {
    id
    title
    description
    s3Key
    s3KeyResized
    userID
    createdAt
    postedTime
    scheduledPostTime
    status
    tiktok {
      title
      caption
      privacy
      usersCanComment
      usersCanDuet
      usersCanStitch
      contentDisclosureEnabled
      contentDisclosureYourBrand
      contentDisclosureBrandedContent
      videoCoverTimestampMs
      postID
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      title
      description
      link
      thumbnailURL
      boardID
      pinID
    }
    youtube {
      title
      description
      tags
      thumbnailURL
      privacy
      category
      targetChannelID
      videoID
    }
    instagram {
      caption
      thumbnail
      targetAccountID
      postToStory
      postID
      storyID
    }
    facebook {
      caption
      thumbnail
      targetPageID
      postToStory
      postID
      storyID
      storyURL
    }
    twitter {
      caption
      tweetID
    }
    postedToTiktok
    postedToLinkedin
    postedToPinterest
    postedToYoutube
    postedToFacebook
    postedToInstagram
    postedToTwitter
    enabledTiktok
    enabledLinkedin
    enabledPinterest
    enabledYoutube
    enabledFacebook
    enabledInstagram
    enabledTwitter
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSocialManPostSubscriptionVariables,
  APITypes.OnDeleteSocialManPostSubscription
>;
