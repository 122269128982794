import { CloudAccessLevels, LocalTaskPrivacyLevels } from "@/modeltypings";
import { TaskPrivacyLevels } from "@mltask/graphql/Codegen/API";

export class AWSManagerCommon {
  protected static man: AWSManagerCommon;
  public static get Instance() {
    if (!this.man) {
      this.man = new AWSManagerCommon();
    }
    return this.man;
  }

  getAccessLevelFromLocalTaskPrivacy(
    localPrivacy: LocalTaskPrivacyLevels
  ): CloudAccessLevels {
    if (localPrivacy == LocalTaskPrivacyLevels.PUBLIC) return "guest";
    if (localPrivacy == LocalTaskPrivacyLevels.PRIVATE) return "private";
    return "guest";
  }

  getAccessLevelFromTaskPrivacy(privacy: TaskPrivacyLevels): CloudAccessLevels {
    if (privacy == TaskPrivacyLevels.public) return "guest";
    if (privacy == TaskPrivacyLevels.private) return "private";
    return "guest";
  }

  getAccessLevelFromPublicBoolean(isPublic: boolean): CloudAccessLevels {
    if (isPublic == true) return "guest";
    if (isPublic == false) return "private";
    return "guest";
  }
}

export default AWSManagerCommon.Instance;
