/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API_WithTypename";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const batchGetUsers = /* GraphQL */ `query BatchGetUsers($ids: [ID]!) {
  batchGetUsers(ids: $ids) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.BatchGetUsersQueryVariables,
  APITypes.BatchGetUsersQuery
>;
export const getUserFromUsername = /* GraphQL */ `query GetUserFromUsername($username: String!) {
  getUserFromUsername(username: $username) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetUserFromUsernameQueryVariables,
  APITypes.GetUserFromUsernameQuery
>;
export const getMLTasksWorkspace = /* GraphQL */ `query GetMLTasksWorkspace($id: ID!) {
  getMLTasksWorkspace(id: $id) {
    id
    name
    description
    userID
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMLTasksWorkspaceQueryVariables,
  APITypes.GetMLTasksWorkspaceQuery
>;
export const listMLTasksWorkspaces = /* GraphQL */ `query ListMLTasksWorkspaces(
  $filter: ModelMLTasksWorkspaceFilterInput
  $limit: Int
  $nextToken: String
) {
  listMLTasksWorkspaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      userID
      MLTaskExecutionResults {
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTasksWorkspacesQueryVariables,
  APITypes.ListMLTasksWorkspacesQuery
>;
export const listMLTasksWorkspaceByCreatedAt = /* GraphQL */ `query ListMLTasksWorkspaceByCreatedAt(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMLTasksWorkspaceFilterInput
  $limit: Int
  $nextToken: String
) {
  listMLTasksWorkspaceByCreatedAt(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      userID
      MLTaskExecutionResults {
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTasksWorkspaceByCreatedAtQueryVariables,
  APITypes.ListMLTasksWorkspaceByCreatedAtQuery
>;
export const getModelInputPreset = /* GraphQL */ `query GetModelInputPreset($id: ID!) {
  getModelInputPreset(id: $id) {
    id
    presetName
    description
    cost
    usage
    isPublic
    isOpenSource
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        entryType
        parameterName
        val
        description
        presetOrder
      }
    }
    versionHistory {
      versionName
      cost
      entries {
        modelInputName
        modelInputType
      }
    }
    versionName
    username
    taskName
    modelName
    modelsID
    userID
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetModelInputPresetQueryVariables,
  APITypes.GetModelInputPresetQuery
>;
export const listModelInputPresets = /* GraphQL */ `query ListModelInputPresets(
  $filter: ModelModelInputPresetFilterInput
  $limit: Int
  $nextToken: String
) {
  listModelInputPresets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      presetName
      description
      cost
      usage
      isPublic
      isOpenSource
      entries {
        modelInputName
        modelInputType
      }
      versionHistory {
        versionName
        cost
      }
      versionName
      username
      taskName
      modelName
      modelsID
      userID
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListModelInputPresetsQueryVariables,
  APITypes.ListModelInputPresetsQuery
>;
export const modelInputPresetsByModelsID = /* GraphQL */ `query ModelInputPresetsByModelsID(
  $modelsID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelModelInputPresetFilterInput
  $limit: Int
  $nextToken: String
) {
  modelInputPresetsByModelsID(
    modelsID: $modelsID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      presetName
      description
      cost
      usage
      isPublic
      isOpenSource
      entries {
        modelInputName
        modelInputType
      }
      versionHistory {
        versionName
        cost
      }
      versionName
      username
      taskName
      modelName
      modelsID
      userID
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ModelInputPresetsByModelsIDQueryVariables,
  APITypes.ModelInputPresetsByModelsIDQuery
>;
export const modelInputPresetsByUserID = /* GraphQL */ `query ModelInputPresetsByUserID(
  $userID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelModelInputPresetFilterInput
  $limit: Int
  $nextToken: String
) {
  modelInputPresetsByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      presetName
      description
      cost
      usage
      isPublic
      isOpenSource
      entries {
        modelInputName
        modelInputType
      }
      versionHistory {
        versionName
        cost
      }
      versionName
      username
      taskName
      modelName
      modelsID
      userID
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ModelInputPresetsByUserIDQueryVariables,
  APITypes.ModelInputPresetsByUserIDQuery
>;
export const getTaskReaction = /* GraphQL */ `query GetTaskReaction($userID: ID!) {
  getTaskReaction(userID: $userID) {
    userID
    type
    mltaskexecutionresultID
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetTaskReactionQueryVariables,
  APITypes.GetTaskReactionQuery
>;
export const listTaskReactions = /* GraphQL */ `query ListTaskReactions(
  $userID: ID
  $filter: ModelTaskReactionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTaskReactions(
    userID: $userID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userID
      type
      mltaskexecutionresultID
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListTaskReactionsQueryVariables,
  APITypes.ListTaskReactionsQuery
>;
export const taskReactionsByMltaskexecutionresultID = /* GraphQL */ `query TaskReactionsByMltaskexecutionresultID(
  $mltaskexecutionresultID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTaskReactionFilterInput
  $limit: Int
  $nextToken: String
) {
  taskReactionsByMltaskexecutionresultID(
    mltaskexecutionresultID: $mltaskexecutionresultID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userID
      type
      mltaskexecutionresultID
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.TaskReactionsByMltaskexecutionresultIDQueryVariables,
  APITypes.TaskReactionsByMltaskexecutionresultIDQuery
>;
export const getMLTaskExecutionResult = /* GraphQL */ `query GetMLTaskExecutionResult($id: ID!) {
  getMLTaskExecutionResult(id: $id) {
    id
    userID
    HEARTReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    WOWReactionStats
    SADReactionStats
    ANGRYReactionStats
    SCARYReactionStats
    POOPReactionStats
    GROSSReactionStats
    Reactions {
      items {
        userID
        type
        mltaskexecutionresultID
        createdAt
        updatedAt
      }
      nextToken
    }
    taskName
    modelName
    privacyLevel
    inputs {
      name
      value
      type
      metaData {
        FILE_NAME
        FILE_TYPE
        FILE_SIZE_KB
        DURATION
        WIDTH
        HEIGHT
        STRING_LENGTH
        TOKENS_COUNT
      }
    }
    output {
      message
      entries {
        name
        value
        type
      }
    }
    creditsUsed
    status
    presetUserInput {
      presetID
      presetVersion
      presetName
      presetCreatorName
      presetCreatorUID
      presetValues {
        modelInputName
      }
    }
    createdAt
    mltasksworkspaceID
    modelsID
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMLTaskExecutionResultQueryVariables,
  APITypes.GetMLTaskExecutionResultQuery
>;
export const listMLTaskExecutionResults = /* GraphQL */ `query ListMLTaskExecutionResults(
  $filter: ModelMLTaskExecutionResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listMLTaskExecutionResults(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      HEARTReactionStats
      CUTEReactionStats
      FUNNYReactionStats
      WOWReactionStats
      SADReactionStats
      ANGRYReactionStats
      SCARYReactionStats
      POOPReactionStats
      GROSSReactionStats
      Reactions {
        nextToken
      }
      taskName
      modelName
      privacyLevel
      inputs {
        name
        value
        type
      }
      output {
        message
      }
      creditsUsed
      status
      presetUserInput {
        presetID
        presetVersion
        presetName
        presetCreatorName
        presetCreatorUID
      }
      createdAt
      mltasksworkspaceID
      modelsID
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTaskExecutionResultsQueryVariables,
  APITypes.ListMLTaskExecutionResultsQuery
>;
export const listMLTaskExecutionResultsByCreatedAt = /* GraphQL */ `query ListMLTaskExecutionResultsByCreatedAt(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMLTaskExecutionResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listMLTaskExecutionResultsByCreatedAt(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      HEARTReactionStats
      CUTEReactionStats
      FUNNYReactionStats
      WOWReactionStats
      SADReactionStats
      ANGRYReactionStats
      SCARYReactionStats
      POOPReactionStats
      GROSSReactionStats
      Reactions {
        nextToken
      }
      taskName
      modelName
      privacyLevel
      inputs {
        name
        value
        type
      }
      output {
        message
      }
      creditsUsed
      status
      presetUserInput {
        presetID
        presetVersion
        presetName
        presetCreatorName
        presetCreatorUID
      }
      createdAt
      mltasksworkspaceID
      modelsID
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTaskExecutionResultsByCreatedAtQueryVariables,
  APITypes.ListMLTaskExecutionResultsByCreatedAtQuery
>;
export const mLTaskExecutionResultsByMltasksworkspaceID = /* GraphQL */ `query MLTaskExecutionResultsByMltasksworkspaceID(
  $mltasksworkspaceID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMLTaskExecutionResultFilterInput
  $limit: Int
  $nextToken: String
) {
  mLTaskExecutionResultsByMltasksworkspaceID(
    mltasksworkspaceID: $mltasksworkspaceID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      HEARTReactionStats
      CUTEReactionStats
      FUNNYReactionStats
      WOWReactionStats
      SADReactionStats
      ANGRYReactionStats
      SCARYReactionStats
      POOPReactionStats
      GROSSReactionStats
      Reactions {
        nextToken
      }
      taskName
      modelName
      privacyLevel
      inputs {
        name
        value
        type
      }
      output {
        message
      }
      creditsUsed
      status
      presetUserInput {
        presetID
        presetVersion
        presetName
        presetCreatorName
        presetCreatorUID
      }
      createdAt
      mltasksworkspaceID
      modelsID
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.MLTaskExecutionResultsByMltasksworkspaceIDQueryVariables,
  APITypes.MLTaskExecutionResultsByMltasksworkspaceIDQuery
>;
export const mLTaskExecutionResultsByModelsID = /* GraphQL */ `query MLTaskExecutionResultsByModelsID(
  $modelsID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMLTaskExecutionResultFilterInput
  $limit: Int
  $nextToken: String
) {
  mLTaskExecutionResultsByModelsID(
    modelsID: $modelsID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      HEARTReactionStats
      CUTEReactionStats
      FUNNYReactionStats
      WOWReactionStats
      SADReactionStats
      ANGRYReactionStats
      SCARYReactionStats
      POOPReactionStats
      GROSSReactionStats
      Reactions {
        nextToken
      }
      taskName
      modelName
      privacyLevel
      inputs {
        name
        value
        type
      }
      output {
        message
      }
      creditsUsed
      status
      presetUserInput {
        presetID
        presetVersion
        presetName
        presetCreatorName
        presetCreatorUID
      }
      createdAt
      mltasksworkspaceID
      modelsID
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.MLTaskExecutionResultsByModelsIDQueryVariables,
  APITypes.MLTaskExecutionResultsByModelsIDQuery
>;
export const getCreditTransactionsHistory = /* GraphQL */ `query GetCreditTransactionsHistory($id: ID!) {
  getCreditTransactionsHistory(id: $id) {
    id
    eventID
    userID
    cashAmountInCents
    creditAmount
    status
    transactionType
    context {
      taskID
      presetID
      presetVersion
      subscriptionID
      priceID
      transactionID
      feedback
      comment
      reason
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetCreditTransactionsHistoryQueryVariables,
  APITypes.GetCreditTransactionsHistoryQuery
>;
export const listCreditTransactionsHistories = /* GraphQL */ `query ListCreditTransactionsHistories(
  $filter: ModelCreditTransactionsHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCreditTransactionsHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventID
      userID
      cashAmountInCents
      creditAmount
      status
      transactionType
      context {
        taskID
        presetID
        presetVersion
        subscriptionID
        priceID
        transactionID
        feedback
        comment
        reason
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListCreditTransactionsHistoriesQueryVariables,
  APITypes.ListCreditTransactionsHistoriesQuery
>;
export const listUserCreditTransactionsHistoryByCreatedAt = /* GraphQL */ `query ListUserCreditTransactionsHistoryByCreatedAt(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCreditTransactionsHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserCreditTransactionsHistoryByCreatedAt(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventID
      userID
      cashAmountInCents
      creditAmount
      status
      transactionType
      context {
        taskID
        presetID
        presetVersion
        subscriptionID
        priceID
        transactionID
        feedback
        comment
        reason
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserCreditTransactionsHistoryByCreatedAtQueryVariables,
  APITypes.ListUserCreditTransactionsHistoryByCreatedAtQuery
>;
export const getModels = /* GraphQL */ `query GetModels($id: ID!) {
  getModels(id: $id) {
    id
    isPublic
    name
    inputs {
      entries {
        type
        name
        defaultValue
        optionType
        optionStep
        description
        examples
        isRequired
      }
    }
    outputs {
      entries {
        type
        name
      }
    }
    mltaskdataID
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetModelsQueryVariables, APITypes.GetModelsQuery>;
export const listModels = /* GraphQL */ `query ListModels(
  $filter: ModelModelsFilterInput
  $limit: Int
  $nextToken: String
) {
  listModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      isPublic
      name
      mltaskdataID
      ModelInputPresets {
        nextToken
      }
      MLTaskExecutionResults {
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListModelsQueryVariables,
  APITypes.ListModelsQuery
>;
export const modelsByMltaskdataID = /* GraphQL */ `query ModelsByMltaskdataID(
  $mltaskdataID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelModelsFilterInput
  $limit: Int
  $nextToken: String
) {
  modelsByMltaskdataID(
    mltaskdataID: $mltaskdataID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      isPublic
      name
      mltaskdataID
      ModelInputPresets {
        nextToken
      }
      MLTaskExecutionResults {
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ModelsByMltaskdataIDQueryVariables,
  APITypes.ModelsByMltaskdataIDQuery
>;
export const getMLTaskData = /* GraphQL */ `query GetMLTaskData($id: ID!) {
  getMLTaskData(id: $id) {
    id
    name
    category
    isPublic
    Models {
      items {
        id
        isPublic
        name
        mltaskdataID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMLTaskDataQueryVariables,
  APITypes.GetMLTaskDataQuery
>;
export const listMLTaskData = /* GraphQL */ `query ListMLTaskData(
  $filter: ModelMLTaskDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listMLTaskData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      category
      isPublic
      Models {
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTaskDataQueryVariables,
  APITypes.ListMLTaskDataQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    username
    currentSubscriptionPlan
    periodEndOfLastSubscriptionCredits
    createdPresetCount
    completedTasksCount
    stripeCustomerID
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    email
    picture
    credits
    redeemableCredits
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        avatarURL
        avatarURL100
        avatarLargeURL
        displayName
        bioDescription
        profileDeepLink
        isVerified
        username
        followerCount
        followingCount
        likesCount
        videoCount
        privacyLevelOptions
        commentDisabled
        duetDisabled
        stitchDisabled
        maxVideoPostDuration_sec
      }
    }
    pinterest {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        scope
        refreshTokenExpirationDate
      }
      userData {
        accountType
        id
        profileImage
        websiteUrl
        username
        about
        businessName
        boardCount
        pinCount
        followerCount
        followingCount
        monthlyViews
      }
      boardsData {
        id
        name
        pinCount
        followerCount
        imageCoverUrl
        createdAt
        description
        privacy
      }
    }
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    google {
      tokenData {
        accessToken
        refreshToken
        scope
        tokenType
        idToken
        expiresOn
      }
      userData {
        id
        email
        verifiedEmail
        name
        givenName
        familyName
        picture
        locale
      }
      channelsData {
        id
        customUrl
        title
        description
        thumbnail
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        refreshToken
        codeVerifier
        oauthTokenSecret
        expiresOn
      }
      userData {
        id
        name
        username
        description
        verifiedType
        profilePictureURL
      }
    }
    facebook {
      userData {
        id
        name
        picture
        accessToken
        expiresOn
      }
      pagesData {
        id
        name
        category
        picture
        accessToken
        expiresOn
      }
      instagramsData {
        connectedPageID
        id
        igID
        biography
        name
        username
        profilePictureURL
        followsCount
        followersCount
        mediaCount
        accessToken
        expiresOn
      }
    }
    SocialManPosts {
      items {
        id
        title
        description
        s3Key
        s3KeyResized
        userID
        createdAt
        postedTime
        scheduledPostTime
        status
        postedToTiktok
        postedToLinkedin
        postedToPinterest
        postedToYoutube
        postedToFacebook
        postedToInstagram
        postedToTwitter
        enabledTiktok
        enabledLinkedin
        enabledPinterest
        enabledYoutube
        enabledFacebook
        enabledInstagram
        enabledTwitter
        updatedAt
      }
      nextToken
    }
    MLTaskExecutionResults {
      items {
        id
        userID
        HEARTReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        WOWReactionStats
        SADReactionStats
        ANGRYReactionStats
        SCARYReactionStats
        POOPReactionStats
        GROSSReactionStats
        taskName
        modelName
        privacyLevel
        creditsUsed
        status
        createdAt
        mltasksworkspaceID
        modelsID
        updatedAt
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        id
        name
        description
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    ModelInputPresets {
      items {
        id
        presetName
        description
        cost
        usage
        isPublic
        isOpenSource
        versionName
        username
        taskName
        modelName
        modelsID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      username
      currentSubscriptionPlan
      periodEndOfLastSubscriptionCredits
      createdPresetCount
      completedTasksCount
      stripeCustomerID
      stripeConnectAccountID
      stripeConnectAccountSetupComplete
      email
      picture
      credits
      redeemableCredits
      SocialManPosts {
        nextToken
      }
      MLTaskExecutionResults {
        nextToken
      }
      MLTasksWorkspaces {
        nextToken
      }
      ModelInputPresets {
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getSocialManPost = /* GraphQL */ `query GetSocialManPost($id: ID!) {
  getSocialManPost(id: $id) {
    id
    title
    description
    s3Key
    s3KeyResized
    userID
    createdAt
    postedTime
    scheduledPostTime
    status
    tiktok {
      title
      caption
      privacy
      usersCanComment
      usersCanDuet
      usersCanStitch
      contentDisclosureEnabled
      contentDisclosureYourBrand
      contentDisclosureBrandedContent
      videoCoverTimestampMs
      postID
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      title
      description
      link
      thumbnailURL
      boardID
      pinID
    }
    youtube {
      title
      description
      tags
      thumbnailURL
      privacy
      category
      targetChannelID
      videoID
    }
    instagram {
      caption
      thumbnail
      targetAccountID
      postToStory
      postID
      storyID
    }
    facebook {
      caption
      thumbnail
      targetPageID
      postToStory
      postID
      storyID
      storyURL
    }
    twitter {
      caption
      tweetID
    }
    postedToTiktok
    postedToLinkedin
    postedToPinterest
    postedToYoutube
    postedToFacebook
    postedToInstagram
    postedToTwitter
    enabledTiktok
    enabledLinkedin
    enabledPinterest
    enabledYoutube
    enabledFacebook
    enabledInstagram
    enabledTwitter
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSocialManPostQueryVariables,
  APITypes.GetSocialManPostQuery
>;
export const listSocialManPosts = /* GraphQL */ `query ListSocialManPosts(
  $filter: ModelSocialManPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listSocialManPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      s3Key
      s3KeyResized
      userID
      createdAt
      postedTime
      scheduledPostTime
      status
      tiktok {
        title
        caption
        privacy
        usersCanComment
        usersCanDuet
        usersCanStitch
        contentDisclosureEnabled
        contentDisclosureYourBrand
        contentDisclosureBrandedContent
        videoCoverTimestampMs
        postID
      }
      linkedin {
        caption
        mediaDescription
        postID
      }
      pinterest {
        title
        description
        link
        thumbnailURL
        boardID
        pinID
      }
      youtube {
        title
        description
        tags
        thumbnailURL
        privacy
        category
        targetChannelID
        videoID
      }
      instagram {
        caption
        thumbnail
        targetAccountID
        postToStory
        postID
        storyID
      }
      facebook {
        caption
        thumbnail
        targetPageID
        postToStory
        postID
        storyID
        storyURL
      }
      twitter {
        caption
        tweetID
      }
      postedToTiktok
      postedToLinkedin
      postedToPinterest
      postedToYoutube
      postedToFacebook
      postedToInstagram
      postedToTwitter
      enabledTiktok
      enabledLinkedin
      enabledPinterest
      enabledYoutube
      enabledFacebook
      enabledInstagram
      enabledTwitter
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSocialManPostsQueryVariables,
  APITypes.ListSocialManPostsQuery
>;
export const listSocialManPostsByCreatedAt = /* GraphQL */ `query ListSocialManPostsByCreatedAt(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSocialManPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listSocialManPostsByCreatedAt(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      s3Key
      s3KeyResized
      userID
      createdAt
      postedTime
      scheduledPostTime
      status
      tiktok {
        title
        caption
        privacy
        usersCanComment
        usersCanDuet
        usersCanStitch
        contentDisclosureEnabled
        contentDisclosureYourBrand
        contentDisclosureBrandedContent
        videoCoverTimestampMs
        postID
      }
      linkedin {
        caption
        mediaDescription
        postID
      }
      pinterest {
        title
        description
        link
        thumbnailURL
        boardID
        pinID
      }
      youtube {
        title
        description
        tags
        thumbnailURL
        privacy
        category
        targetChannelID
        videoID
      }
      instagram {
        caption
        thumbnail
        targetAccountID
        postToStory
        postID
        storyID
      }
      facebook {
        caption
        thumbnail
        targetPageID
        postToStory
        postID
        storyID
        storyURL
      }
      twitter {
        caption
        tweetID
      }
      postedToTiktok
      postedToLinkedin
      postedToPinterest
      postedToYoutube
      postedToFacebook
      postedToInstagram
      postedToTwitter
      enabledTiktok
      enabledLinkedin
      enabledPinterest
      enabledYoutube
      enabledFacebook
      enabledInstagram
      enabledTwitter
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSocialManPostsByCreatedAtQueryVariables,
  APITypes.ListSocialManPostsByCreatedAtQuery
>;
