/* tslint:disable */
/* eslint-disable */

export const updateUserUsername = /* GraphQL */ `
  mutation updateUsername($userID: ID!, $username: String!) {
    updateUserUsername(userID: $userID, username: $username) {
      id
      username
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUserCustom(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      username
      stripeCustomerID
      stripeConnectAccountID
      stripeConnectAccountSetupComplete
      email
      picture
      credits
      MLTaskExecutionResults {
        nextToken
        __typename
      }
      MLTasksWorkspaces {
        nextToken
        __typename
      }
      ModelInputPresets {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateUserRemoveNetworkMutation = /* GraphQL */ `
  mutation UpdateUserRemoveNetwork($input: UpdateUserInput!) {
    updateUser(input: $input) {
      facebook {
        instagramsData {
          accessToken
          biography
          connectedPageID
          expiresOn
          followersCount
          followsCount
          id
          igID
          mediaCount
          name
          profilePictureURL
          username
        }
        pagesData {
          accessToken
          category
          expiresOn
          id
          name
          picture
        }
        userData {
          accessToken
          expiresOn
          id
          name
          picture
        }
      }
      google {
        channelsData {
          customUrl
          description
          id
          thumbnail
          title
        }
        tokenData {
          accessToken
          expiresOn
          idToken
          refreshToken
          scope
          tokenType
        }
        userData {
          email
          familyName
          givenName
          id
          locale
          name
          picture
          verifiedEmail
        }
      }
      id
      linkedin {
        tokenData {
          accessToken
          accessTokenExpirationDate
          scope
        }
        userData {
          email
          name
          picture
          sub
        }
      }
      pinterest {
        boardsData {
          createdAt
          description
          followerCount
          id
          imageCoverUrl
          name
          pinCount
          privacy
        }
        tokenData {
          accessToken
          accessTokenExpirationDate
          refreshToken
          refreshTokenExpirationDate
          scope
        }
        userData {
          about
          accountType
          boardCount
          businessName
          followerCount
          followingCount
          id
          monthlyViews
          pinCount
          profileImage
          username
          websiteUrl
        }
      }
      tiktok {
        tokenData {
          accessToken
          accessTokenExpirationDate
          refreshToken
          refreshTokenExpirationDate
          scope
        }
        userData {
          avatarLargeURL
          avatarURL
          avatarURL100
          bioDescription
          commentDisabled
          displayName
          duetDisabled
          followerCount
          followingCount
          isVerified
          likesCount
          maxVideoPostDuration_sec
          privacyLevelOptions
          profileDeepLink
          stitchDisabled
          username
          videoCount
        }
      }
      twitter {
        tokenData {
          accessToken
          accessTokenSecret
          codeVerifier
          expiresOn
          oauthTokenSecret
          refreshToken
        }
        userData {
          description
          id
          name
          profilePictureURL
          username
          verifiedType
        }
      }
    }
  }
`;

export const createModels = /* GraphQL */ `
  mutation CustomCreateModels(
    $input: CreateModelsInput!
    $condition: ModelModelsConditionInput
  ) {
    createModels(input: $input, condition: $condition) {
      isPublic
      name
      inputs {
        __typename
      }
      outputs {
        __typename
      }
      mltaskdataID
      ModelInputPresets {
        nextToken
        __typename
      }
      MLTaskExecutionResults {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
